import { PropsWithChildren } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

type FormProps<T extends FieldValues, C> = PropsWithChildren<{
  formUtils: UseFormReturn<T, C, undefined>;
  onSubmit?: (data: T) => void;
}>;
export function Form<T extends FieldValues, C>({ children, formUtils, onSubmit }: FormProps<T, C>) {
  return (
    <FormProvider {...formUtils}>
      <form noValidate onSubmit={onSubmit && formUtils.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}
