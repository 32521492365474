import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { enqueueSnackbar, OptionsWithExtraProps } from 'notistack';

export function useNotification() {
  const intl = useIntl();

  return {
    showError: (message: ReactNode, options?: OptionsWithExtraProps<'error'>) => {
      enqueueSnackbar(message, {
        variant: 'error',
        ...options,
      });
    },
    showSuccess: (message: ReactNode, options?: OptionsWithExtraProps<'success'>) => {
      enqueueSnackbar(message, {
        variant: 'success',
        ...options,
      });
    },
    showSuccessIntl: (message: string, options?: OptionsWithExtraProps<'success'>) => {
      enqueueSnackbar(intl.formatMessage({ id: message }), {
        variant: 'success',
        ...options,
      });
    },
  };
}
