// For some reason the eslint is not recognizing the icons as valid types
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  faListTimeline,
  faSwatchbook,
  faChevronRight,
  faBell,
  faCircleQuestion,
  faList,
  faCalendar,
  faUserMagnifyingGlass,
  faXmark,
  faMemoPad,
  faUsers,
  faBuildingMemo,
  faCheckCircle,
  faAddressCard,
  faUsersGear,
  faAngleDown,
  faSquare,
  faSquareCheck,
  faGrid2,
  faBulldozer,
  faAngleRight,
  faUserGear,
  faMessagesQuestion,
  faCircleExclamation,
  faArrowRightFromBracket,
  faAddressBook,
  faStore,
  faGear,
  faCoins,
  faArrowLeft,
  faArrowRight,
  faChevronDown,
  faShieldHalved,
  faPaperPlane,
  faPlus,
  faAngleLeft,
  faCircleXmark,
  faCalendarLines,
  faUserCheck,
  faNotesMedical,
  faCircleInfo,
  faPen,
  faNotes,
  faCircleDot,
  faXmarkLarge,
  faTrashCan,
  faEllipsisVertical,
} from '@awesome.me/kit-175d6aa7e6/icons/duotone/solid';
import { regularIcons } from './regular';

export const duotoneIcons: typeof regularIcons = {
  'list-timeline': faListTimeline,
  swatchbook: faSwatchbook,
  'chevron-right': faChevronRight,
  bell: faBell,
  'circle-question': faCircleQuestion,
  list: faList,
  calendar: faCalendar,
  'user-magnifying-glass': faUserMagnifyingGlass,
  xmark: faXmark,
  'memo-pad': faMemoPad,
  users: faUsers,
  'building-memo': faBuildingMemo,
  'check-circle': faCheckCircle,
  'address-card': faAddressCard,
  'users-gear': faUsersGear,
  'angle-down': faAngleDown,
  square: faSquare,
  'square-check': faSquareCheck,
  'grid-2': faGrid2,
  bulldozer: faBulldozer,
  'angle-right': faAngleRight,
  'user-gear': faUserGear,
  'user-check': faUserCheck,
  'messages-question': faMessagesQuestion,
  'circle-exclamation': faCircleExclamation,
  'arrow-right-from-bracket': faArrowRightFromBracket,
  'arrow-left': faArrowLeft,
  'arrow-right': faArrowRight,
  'address-book': faAddressBook,
  store: faStore,
  gear: faGear,
  coins: faCoins,
  'chevron-down': faChevronDown,
  'shield-halved': faShieldHalved,
  'paper-plane': faPaperPlane,
  plus: faPlus,
  'angle-left': faAngleLeft,
  'circle-xmark': faCircleXmark,
  'calendar-lines': faCalendarLines,
  'notes-medical': faNotesMedical,
  'circle-info': faCircleInfo,
  pen: faPen,
  notes: faNotes,
  'circle-dot': faCircleDot,
  'xmark-large': faXmarkLarge,
  'trach-can': faTrashCan,
  'ellipsis-vertical': faEllipsisVertical,
};
