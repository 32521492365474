import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NestedMenuItem } from 'mui-nested-menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import { Box, Flex } from '@atoms/layout';
import { Avatar } from '@atoms/dataDisplay';
import { FaIcon } from '@atoms/icons';
import { DuotoneIcon } from '@atoms/icons';
import { useAuthStore } from '@libs/store/auth';
import { useNavigate } from 'react-router-dom';

export function ProfileMenu() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { flushToken } = useAuthStore();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    flushToken();
    navigate('/login');
  };

  // TODO: uses real user
  const userMock = {
    name: 'Barbara Dorsey',
    email: 'barbara.dorsey@gmail.com',
    avatar: undefined,
  };

  return (
    <>
      <Tooltip title={<FormattedMessage id="header.manageAccount" />}>
        <IconButton
          id="profile-button"
          aria-controls={open ? 'profile-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <HeaderAvatar src={userMock.avatar} />
        </IconButton>
      </Tooltip>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'profile-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Flex mt={1} mb={0.5}>
            <Avatar src={userMock.avatar} />
            <Flex column ml={2} maxWidth="400px">
              <Typography noWrap>{userMock.name}</Typography>
              <Typography variant="body2" color="text.secondary" noWrap>
                {userMock.email}
              </Typography>
            </Flex>
          </Flex>
        </MenuItem>
        <Divider />
        <NestedMenuItem
          parentMenuOpen={open}
          rightIcon={<FaIcon name="angle-right" />}
          label={intl.formatMessage({
            id: 'header.profileMenu.pauseNotificationsLabel',
          })}
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          }}
        >
          <Box minWidth="220px">
            <Box mx={1} mt={1}>
              <Typography variant="body2" color="text.secondary">
                <FormattedMessage id="header.notificationSchedule.label" />
              </Typography>
            </Box>
            <MenuItem onClick={handleClose}>
              <ListItemText>
                <FormattedMessage id="header.notificationSchedule.postponeMins" values={{ value: 30 }} />
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemText>
                <FormattedMessage id="header.notificationSchedule.postponeHours" values={{ value: 1 }} />
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemText>
                <FormattedMessage id="header.notificationSchedule.postponeHours" values={{ value: 2 }} />
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemText>
                <FormattedMessage id="header.notificationSchedule.postponeTomorrow" />
              </ListItemText>
            </MenuItem>
          </Box>
        </NestedMenuItem>
        <Divider sx={{ my: 1 }} />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DuotoneIcon name="user-gear" active />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="header.profileMenu.profileSettingsLabel" />
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DuotoneIcon name="messages-question" active />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="header.profileMenu.supportLabel" />
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DuotoneIcon name="circle-exclamation" active />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="header.profileMenu.reportLabel" />
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <FaIcon name="arrow-right-from-bracket" />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="header.profileMenu.logOutLabel" />
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

const HeaderAvatar = styled(Avatar)({
  width: '32px',
  height: '32px',
});
