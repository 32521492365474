import { forwardRef, ReactNode } from 'react';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import { FaIcon } from '@atoms/icons';
import { useTheme } from '@mui/material/styles';

export type CheckboxProps = MuiCheckboxProps & {
  label?: ReactNode;
  onChange?: (event: React.SyntheticEvent, checked: boolean) => void;
  formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
};
export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(function Checkbox(
  { label, onChange, sx, formControlLabelProps, ...props },
  ref,
) {
  const theme = useTheme();

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          ref={ref}
          icon={<FaIcon name="square" color={theme.palette.grey[500]} />}
          checkedIcon={<FaIcon name="square-check" color="primary" />}
          sx={{ m: '3px', ...sx }}
          {...props}
        />
      }
      label={label}
      onChange={onChange}
      {...formControlLabelProps}
      sx={{
        marginLeft: 0,
        ...formControlLabelProps?.sx,
      }}
    />
  );
});
