import { forwardRef, ReactNode, useEffect, useMemo, useState } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { debounce } from '@mui/material/utils';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { getAddressValue, SearchAddressResultOutput } from '@framework/src/libs/maps';
import { TextField } from './textField';
import { SearchAddressResultItemOutput } from '@azure-rest/maps-search';

// TODO: value from outside

type AddressAutocompleteProps = Omit<
  AutocompleteProps<Place | string, false, false, false>,
  'options' | 'renderInput' | 'onChange' | 'value'
> & {
  onChange?: (value: Place | null) => void;
  label?: ReactNode;
  fullWidth?: boolean;
  required?: boolean;
  placeholder?: string;
  value?: string | null;
  error?: string;
};
export const AddressAutocomplete = forwardRef<HTMLDivElement, AddressAutocompleteProps>(function AddressAutocomplete(
  { onChange, label, fullWidth, required, placeholder, value, error, ...props },
  ref,
) {
  const [options, setOptions] = useState<Place[]>([]);
  const [inputValue, setInputValue] = useState(value ?? '');

  const fetch = useMemo(
    () =>
      debounce(async (input: string, cb: (data: SearchAddressResultOutput) => void) => {
        const result = await getAddressValue(input);
        cb(result);
      }, 400),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '' || inputValue.length < 3) {
      setOptions([]);
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetch(inputValue, (results?: SearchAddressResultOutput) => {
      if (active) {
        let newOptions: Place[] = [];

        if (results) {
          newOptions = [
            ...newOptions,
            ...results.results.map((r) => ({
              description: r.address.freeformAddress ?? '',
              details: `${r.address.countryCode ?? ''} ${r.address.countrySubdivision ?? ''}`,
              city: r.address.municipality ?? '',
              state: r.address.countrySubdivision ?? '',
              zip: r.address.postalCode ?? '',
              original: r,
            })),
          ];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      value={value}
      onChange={(_, newValue) => {
        // We want user to select only from the list
        // so we can fill city, state and zip fields
        if (typeof newValue !== 'string') {
          onChange?.(newValue);
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => {
        const toCompare = typeof value === 'string' ? value : value.description;
        if (typeof option === 'string') {
          return toCompare === option;
        }
        return toCompare === option.description;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            fullWidth={fullWidth}
            required={required}
            placeholder={placeholder}
            ref={ref}
            error={!!error}
            helperText={error}
          />
        );
      }}
      renderOption={(props, option) => {
        // eslint-disable-next-line react/prop-types
        const { id, ...optionProps } = props;
        return (
          <li {...optionProps} key={id}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                <Typography variant="body1">{typeof option === 'string' ? option : option.description}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {typeof option === 'string' ? '' : option.details}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      {...props}
    />
  );
});

export type Place = {
  description: string;
  details: string;
  city: string;
  state: string;
  zip: string;
  original: SearchAddressResultItemOutput;
};
