import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { DuotoneIcon } from '@atoms/icons';
import { Box, Flex } from '@atoms/layout';
import { MenuItem, Sidebar } from '@organisms/navigation/sidebar';
import { Header } from '@organisms/navigation/header';
import { drawerWidth, headerHeight } from '../../theme';

export function MainLayout({ children }: PropsWithChildren) {
  const theme = useTheme();
  return (
    <Flex bgcolor={theme.palette.applicationBackground}>
      <Header
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
        }}
      />

      <Box component="nav" sx={{ width: drawerWidth, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        <Sidebar topItems={sidebarTopItems} bottomItems={sidebarBottomItems} />
      </Box>

      <Flex
        column
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
        height="100vh"
      >
        <Toolbar />
        <Container
          maxWidth="xl"
          sx={{
            height: `calc(100% - ${headerHeight}px)`,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {children}
        </Container>
      </Flex>
    </Flex>
  );
}

const sidebarTopItems: MenuItem[] = [
  {
    label: <FormattedMessage id="sidebar.dashboard" />,
    icon: ({ active }) => <DuotoneIcon name="grid-2" active={active} />,
    id: 'dashboard',
    path: '',
  },
  {
    label: <FormattedMessage id="sidebar.schedule" />,
    icon: ({ active }) => <DuotoneIcon name="calendar-lines" active={active} />,
    id: 'schedule',
    path: 'schedule',
  },
  {
    label: <FormattedMessage id="sidebar.talent" />,
    icon: ({ active }) => <DuotoneIcon name="address-card" active={active} />,
    id: 'talent',
    path: 'talents',
  },
  {
    label: <FormattedMessage id="sidebar.attendance" />,
    icon: ({ active }) => <DuotoneIcon name="list-timeline" active={active} />,
    id: 'attendance',
    path: 'attendance',
  },
  {
    label: <FormattedMessage id="sidebar.contacts" />,
    icon: ({ active }) => <DuotoneIcon name="address-book" active={active} />,
    id: 'contacts',
    path: 'contacts',
  },
  {
    label: (
      <Box sx={{ textAlign: 'center' }}>
        <FormattedMessage id="sidebar.staffingPartners" />
      </Box>
    ),
    icon: ({ active }) => <DuotoneIcon name="users" active={active} />,
    id: 'staffing-partners',
    path: 'staffing-partners',
  },
  {
    label: <FormattedMessage id="sidebar.marketplace" />,
    icon: ({ active }) => <DuotoneIcon name="store" active={active} />,
    id: 'marketplace',
    path: 'marketplace',
  },
];
const sidebarBottomItems: MenuItem[] = [
  {
    label: <FormattedMessage id="sidebar.settings" />,
    icon: ({ active }) => <DuotoneIcon name="gear" active={active} />,
    id: 'settings',
    path: 'settings',
  },
  {
    label: <FormattedMessage id="sidebar.billing" />,
    icon: ({ active }) => <DuotoneIcon name="coins" active={active} />,
    id: 'billing',
    path: 'billing',
  },
  {
    label: <FormattedMessage id="sidebar.help" />,
    icon: ({ active }) => <DuotoneIcon name="circle-question" active={active} />,
    id: 'help',
    path: 'help',
  },
];
