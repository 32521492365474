import { BrandingIcon, ClientsIcon, DuotoneIcon, OrganizationIcon, TalentsIcon, UsersIcon } from '@atoms/icons';

export const onboardingItems = [
  {
    id: 'organization',
    icon: <OrganizationIcon active />,
    titleKey: 'onboarding.organization.title',
    subtitleKey: 'onboarding.organization.subtitle',
    subtitleLongKey: 'onboarding.organization.subtitleLong',
    actionButtonLabel: 'onboarding.widget.addDetails',
    actionLink: '/settings/organization/details',
  },
  {
    id: 'branding',
    icon: <BrandingIcon active />,
    titleKey: 'onboarding.branding.title',
    subtitleKey: 'onboarding.branding.subtitle',
    subtitleLongKey: 'onboarding.branding.subtitleLong',
    actionButtonLabel: 'onboarding.widget.setBranding',
    actionLink: '/settings/organization/branding',
  },
  {
    id: 'users',
    icon: <UsersIcon active />,
    titleKey: 'onboarding.users.title',
    titleAlternativeKey: 'onboarding.users.titleAlternative',
    subtitleKey: 'onboarding.users.subtitle',
    subtitleLongKey: 'onboarding.users.subtitleLong',
    actionButtonLabel: 'onboarding.widget.getStarted',
    actionLink: '/settings/users',
  },
  {
    id: 'staffingPartners',
    icon: <ClientsIcon active />,
    titleKey: 'onboarding.staffingPartners.title',
    subtitleKey: 'onboarding.staffingPartners.subtitle',
    subtitleLongKey: 'onboarding.staffingPartners.subtitleLong',
    actionButtonLabel: 'onboarding.widget.getStarted',
    actionLink: '/staffing-partners',
  },
  {
    id: 'talents',
    icon: <TalentsIcon active />,
    titleKey: 'onboarding.talents.title',
    subtitleKey: 'onboarding.talents.subtitle',
    subtitleLongKey: 'onboarding.talents.subtitleLong',
    actionButtonLabel: 'onboarding.widget.addDetails',
    actionLink: '/talents',
  },
  {
    id: 'shifts',
    icon: <DuotoneIcon name="calendar-lines" active />,
    titleKey: 'onboarding.schedule.title',
    subtitleKey: 'onboarding.schedule.subtitle',
    subtitleLongKey: 'onboarding.schedule.subtitleLong',
    actionButtonLabel: 'onboarding.widget.getStarted',
    actionLink: '/schedule',
  },
];
