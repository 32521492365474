import { create } from 'zustand';
import { adminRegister } from '@libs/api/auth';

type State = {
  adminSignUp: () => Promise<void>;
  loading: boolean;
};

export const useSignUpStore = create<State>()((set) => ({
  loading: false,
  adminSignUp: async () => {
    set({
      loading: true,
    });
    try {
      await adminRegister();
    } finally {
      set({
        loading: false,
      });
    }
  },
}));
