import { create } from 'zustand';
import { Talent } from '@libs/models/talents';

type State = {
  loading: boolean;
  initiated: boolean;
  talents: Talent[];
};

export const useTalentsStore = create<State>()(() => ({
  loading: false,
  initiated: false,
  talents: [
    {
      id: '1',
      name: 'John Doe',
      qualifications: ['RN', 'LPN/LVN'],
    },
    {
      id: '2',
      name: 'Jane Doe 2',
      qualifications: ['CNA', 'HCA'],
    },
    {
      id: '3',
      name: 'John Doe 3',
      qualifications: ['CNA'],
    },
    {
      id: '4',
      name: 'Jane Doe 4',
      qualifications: ['Med Tech'],
    },
  ],
}));
