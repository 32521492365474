import { useEffect } from 'react';
import { create } from 'zustand';
import { fetchJobQualifications } from '@libs/api/talents';
import { JobQualification } from '@libs/models/talents/jobQualification';

type InitialState = {
  loading: boolean;
  initiated: boolean;
  data: JobQualification[];
  specialties: string[];
  load: () => void;
};

export const useJobQualificationsStore = create<InitialState>()((set) => ({
  loading: false,
  initiated: false,
  data: [],
  specialties: [],
  load: async () => {
    set({ loading: true });
    try {
      const { data } = await fetchJobQualifications();
      const specialties = [...new Set<string>(data.data.flatMap((d) => d.specialties))];
      set({ loading: false, data: data.data, specialties });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
}));

export function useJobQualificationsData() {
  const { load, loading, data, initiated } = useJobQualificationsStore();

  useEffect(() => {
    if (!initiated && !loading) {
      load();
    }
  }, [load, initiated, loading]);

  return { loading, data, initiated };
}

export function useJobQualifications() {
  const { data } = useJobQualificationsStore();

  return data;
}
