import { create } from 'zustand';
import { login, resendOtp, submitOtp } from '@libs/api/auth';
import { updateToken } from '@libs/api/request';
import { parseError } from '@libs/api/errors';

type State = {
  login: (email: string) => Promise<void>;
  flushToken: () => void;
  submitOtp: (email: string, otp: string) => Promise<void>;
  resendOtp: () => Promise<void>;
  isAuthenticated: () => boolean;
  loading: boolean;
};

export const useAuthStore = create<State>()((set) => ({
  loading: false,
  login: async (email) => {
    set({
      loading: true,
    });
    try {
      await login(email);
    } finally {
      set({
        loading: false,
      });
    }
  },

  flushToken: () => {
    localStorage.setItem('token', '');
  },
  submitOtp: async (email, otp) => {
    set({
      loading: true,
    });
    try {
      const res = await submitOtp(email, otp);
      const token = res.data;
      localStorage.setItem('token', token);
      updateToken(token);
    } catch (error) {
      throw parseError(error);
    } finally {
      set({
        loading: false,
      });
    }
  },
  resendOtp: async () => {
    set({
      loading: true,
    });
    try {
      await resendOtp();
    } finally {
      set({
        loading: false,
      });
    }
  },
  isAuthenticated: () => {
    return !!localStorage.getItem('token');
  },
}));
