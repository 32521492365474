import { Shift } from '@libs/models/shifts';

export function fetchShiftsList(page = 0, pageSize = 10, org: string) {
  const data = shiftsMock.slice(page * pageSize, (page + 1) * pageSize);

  // TODO: Hardcode to be able to test empty shift table
  // will be removed once the API is integrated
  if (org === '2') {
    return Promise.resolve({
      data: [],
      meta: {
        total: 0,
      },
    });
  }

  return Promise.resolve({
    data,
    meta: {
      total: shiftsMock.length,
    },
  });
}

const shiftsMock = generateRowsMock();

function generateRowsMock(): Shift[] {
  return Array.from({ length: 100 }, (_, index) => ({
    id: `${index + 1}`,
    shiftStart: new Date(
      `2022-01-${((Math.round(index / 3) % 29) + 1)
        .toString()
        .padStart(2, '0')}T${randomFromArray(['10', '16', '22'])}:00:00`,
    ),
    shiftLength: 8 * 60 * 60 * 1000,
    recurrence: randomFromArray(['single', 'series', 'rotation']),
    location: {
      name: 'Hill Valley Gig Harbor',
      address1: '123 Main St',
      address2: 'Suite 100',
      city: 'Los Angeles',
      state: 'CA',
    },
    qualifications: 'CNA, RN',
    specialty: 'Acute Care, Ambulatory Care',
    status: randomFromArray(['assigned', 'reAssigned', 'pending', 'inProgress', 'completed', 'canceled', 'na']),
    notes: randomFromArray([
      'Some note',
      undefined,
      '',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    ]),
    assignee: randomFromArray([
      null,
      {
        avatar: '',
        name: 'John Doe',
        assignedByClient: false,
        id: 'test-user1',
        email: 'test-user1@example.com',
        phonePrimary: '',
        phoneSecondary: '',
      },
      {
        avatar: '',
        name: 'John Doe',
        assignedByClient: true,
        id: 'test-user2',
        email: 'test-user2@example.com',
        phonePrimary: '',
        phoneSecondary: '',
      },
    ]),
  }));
}

function randomFromArray<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}
