import { flattenMessages } from '../flattenMessages';

export default flattenMessages({
  common: {
    saveChanges: 'Save changes',
    cancel: 'Cancel',
    save: 'Save',
    submit: 'Submit',
    weekDays: {
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
    },
    greeting: 'Hello, {name}!',
    clear: 'Clear',
    applyFilters: 'Apply filters',
    back: 'Back',
    next: 'Next',
    search: 'Search',
    filter: 'Filter',
    all: 'All',
    name: 'Name',
    clearAllFilters: 'Clear all filters',
  },
  auth: {
    login: {
      title: 'Login',
      subtitle: 'Please, provide your email or phone number to receive an OTP',
      tabEmailTitle: 'Email',
      tabPhoneTitle: 'Phone number',
      emailPlaceholder: 'e.g. jhondoe@gmail.com',
      signUpText: 'Don’t have a ShiftNex account yet? <link>Sign Up</link>',
    },
    otp: {
      title: 'Secure verification',
      subtitle: 'We’ve just sent you a temporary 6-digit code verification. Please check email and/or phone.',
      inputPlaceholder: 'Enter the code',
      resendCode: 'Resend the code',
      resendCodeSuccess: 'Code successfully resent',
    },
    adminSignUp: {
      title: 'Welcome to ShiftNex!',
      titleWithName: '{name}, welcome to ShiftNex!',
      subtitle: 'Join the ShiftNex Healthcare Platform and enjoy the way we manage healthcare staffing operations',
      otpBySmsLabel: 'Receive OTP by sms',
      otpBySmsInstructions: 'Please, fill in your phone number if you want receive the one-time password  by SMS',
      submitLabel: 'Create account',
    },
    adminSignUpDetails: {
      title: 'Agency',
      subtitle: 'Please, fill in your agency details.',
    },
    adminSignUpAddress: {
      title: 'Address',
      subtitle: 'Please, fill in your agency address details.',
    },
    footer: {
      legalMessage:
        'By using {name} you agree to the <termsLink>Terms of Service</termsLink> and <privacyLink>Privacy Policy</privacyLink>',
      version: 'Version {version}',
    },
    submitLabel: 'Continue',
    signInText: 'Already have a ShiftNex account? <link>Sign In</link>',
  },
  sidebar: {
    poweredBy: 'Powered by Shiftnex',
    staffingPartners: 'Staffing Partners',
    dashboard: 'Dashboard',
    schedule: 'Schedule',
    contacts: 'Contacts',
    talent: 'Talent',
    attendance: 'Attendance',
    marketplace: 'Marketplace',
    settings: 'Settings',
    billing: 'Billing',
    help: 'Help',
  },
  onboarding: {
    progress: {
      gettingStarted: 'Getting started',
      menuHeader: 'Onboarding',
    },
    organization: {
      title: 'Organization setup',
      subtitle: 'Fill in your organization details',
      subtitleLong:
        'Please fill in your organization information so the ShiftNex can proceed with verifying your account',
    },
    branding: {
      title: 'Branding',
      subtitle: 'Set up your facility’s unique style',
      subtitleLong: 'Set up your facility’s unique style.',
    },
    users: {
      title: 'Roles & Users',
      titleAlternative: 'Roles and users',
      subtitle: 'Add users and set up the roles',
      subtitleLong:
        'Add the users of your organization. Customize the roles to provide the access to your organization’s users.',
    },
    staffingPartners: {
      title: 'Staffing partners',
      subtitle: 'Set up the relationship with agencies',
      subtitleLong: 'Set up the relationship with the agencies to share the shift.',
    },
    talents: {
      title: 'Talents',
      subtitle: 'Add talents working in your agency',
      subtitleLong: 'Add the talents working in your agency so you can assign them to the shared shifts.',
    },
    schedule: {
      title: 'Schedule',
      subtitle: 'Create and assign shifts',
      subtitleLong: 'Create the shifts to be assigned on talents.',
    },
    widget: {
      hide: 'Hide',
      previousStep: 'Previous step',
      nextStep: 'Next step',
      addDetails: 'Add details',
      setBranding: 'Set branding',
      getStarted: 'Get started',
    },
  },
  license: {
    trial: {
      daysLeft: '{days} days left in trial',
      upgradeBtnLabel: 'Upgrade',
    },
  },
  header: {
    manageAccount: 'Manage account',
    profileMenu: {
      pauseNotificationsLabel: 'Pause notifications',
      profileSettingsLabel: 'Profile settings',
      supportLabel: 'Support chat',
      reportLabel: 'Report an issue',
      logOutLabel: 'Log out',
    },
    notificationSchedule: {
      label: 'Pause notifications for...',
      postponeMins: 'For {value, plural, one {# minute} other {# minutes}}',
      postponeHours: 'For {value, plural, one {# hour} other {# hours}}',
      postponeTomorrow: 'Until tomorrow',
    },
  },
  comingSoon: {
    title: 'Coming soon...',
    description: 'The page is not yet available but we are working on it',
  },
  settings: {
    menu: {
      organization: {
        title: 'Organization',
      },
      rolesAndUsers: {
        title: 'Roles and users',
      },
      privacyAndSecurity: {
        title: 'Privacy and security',
      },
      organizationDetails: {
        title: 'Organization details',
      },
      documents: {
        title: 'Documents',
      },
      branding: {
        title: 'Branding',
      },
      qualificationsSpecialties: {
        title: 'Qualifications & Specialties',
      },
      qualifications: {
        title: 'Qualifications',
      },
      specialties: {
        title: 'Specialties',
      },
      departments: {
        title: 'Departments',
      },
      locations: {
        title: 'Locations',
      },
    },
    organization: {
      savedSuccessfully: 'Organization details saved successfully',
      title: 'Organization details',
      subtitle: 'Please fill in your organization information so the ShiftNex can proceed with verifying your account',
      mainDetails: {
        title: 'Main details',
        subtitle: 'Please, fill in organization contact details and EIN',
      },
      address: {
        title: 'Facility address',
        subtitle: 'Please, fill in your facility address details.',
      },
      contact: {
        title: 'Main facility contact',
        subtitle: 'Please, fill in contact details',
      },
      operational: {
        title: 'Operational setup',
        subtitle: 'Set up the way organization operates',
      },
      fields: {
        orgName: {
          label: 'Organization name',
        },
        orgType: {
          label: 'Facility type',
          placeholder: 'Please select a types of your facility',
        },
        orgRegistrationDate: {
          label: 'Registration date',
        },
        numberOfEmployees: {
          label: 'Number of employees',
          placeholder: 'PLease select an option',
        },
        website: {
          label: 'Facility website',
          placeholder: 'http://example.com',
        },
        NPI: {
          label: 'NPI',
          placeholder: 'e.g. 123-45-6789',
        },
      },
    },
    qualSpeck: {
      qualifications: {
        title: 'Qualifications',
        subtitle:
          'In this section you can add qualifications used in your organization. You can select default values provided by the system or create your custom ones. Please note that qualification is mandatory for all users.',

        directQualBox: {
          title: 'Clinical',
          titleInfoTooltip: 'This is a tooltip for direct',
          emptyListPlaceholderText: 'No qualifications inside "Nurses" category',
          editQualListButtonLabel: 'Edit qualifications',
        },
        editDirectQalDialog: {
          dialogHeading: 'Clinical',
          listCounter: '{amount} qualifications',
          addButton: 'Add qualification',
          selectTitle: 'Qualification',
          selectPlaceholder: 'Qualification',
          abbreviationTitle: 'Abbreviation',
          noAbbreviationLabel: 'no qualification chosen',
        },
        otherQualBox: {
          title: 'Other',
          titleInfoTooltip: 'This is a tooltip for other',
          emptyListPlaceholderText: 'No qualifications inside "Other" category',
          editQualListButtonLabel: 'Edit qualifications',
        },
        editOtherQalDialog: {
          dialogHeading: 'Other',
          listCounter: '{amount} qualifications',
          addButton: 'Add qualification',
          selectTitle: 'Qualification',
          abbreviationTitle: 'Abbreviation',
        },
      },
      specialties: {
        title: 'Specialties',
        subtitle:
          'Here you can add specialties of your facility. Each specialty should be mapped to one or multiple qualifications.',
        specialtiesCounter: '{amount} Specialties',
        addSpecialtyButtonLabel: 'Add specialty',
        table: {
          noRows: {
            title: 'No specialties yet',
            subtitle: "You don't have any specialties yet",
          },
          columns: {
            specialty: {
              heading: 'Specialty',
            },
            qualification: {
              heading: 'Qualification',
            },
          },
        },
        editSpecialtyDialog: {
          heading: 'Edit Specialty',
          selectTitle: 'Specialty title',
          selectTitlePlaceholder: 'Please select specialty',
          directQualificationsSectHeading: 'Clinical qualifications',
          otherQualificationsSectHeading: 'Other qualifications',
        },
        addSpecialtyDialog: {
          heading: 'New Specialty',
          selectTitle: 'Specialty title',
          selectTitlePlaceholder: 'Please select specialty',
          directQualificationsSectHeading: 'Clinical qualifications',
          otherQualificationsSectHeading: 'Other qualifications',
        },
      },
    },
    departments: {
      listPage: {
        title: 'Departments',
        subtitle:
          'Add departments to your organization structure. Than you can add sub-departments and lower sub-levels (up to five). In this tab you can also select qualifications and specialties required for each department. Note that sub-departments qualifications can be added from department’s list.',
        departmentsCounter: '{amount} Departments',
        addDepartmentButton: 'Add department',
        itemTypeLabels: {
          department: 'Department',
          subDepartment: 'Sub-department',
          team: 'Team',
          unit: 'Unit',
        },
        itemMenu: {
          editButton: {
            tooltip: 'Edit',
          },
          removeButton: {
            tooltip: 'Delete',
          },
          addButton: {
            tooltip: 'Add a sub element',
          },
        },
      },
      newDepartmentDialog: {
        dialogHeading: 'New department',
        directQualificationsSectHeading: 'Clinical qualifications',
        otherQualificationsSectHeading: 'Other qualifications',
        specialtiesSectHeading: 'Specialties',
        deptTypeSelection: {
          title: 'Type',
        },
        deptTitleSelection: {
          title: 'Title',
        },
        addSpecialtyButton: {
          label: 'Add specialty',
        },
        specialtySection: {
          title: 'Specialty',
        },
      },
      editDepartmentDialog: {
        dialogHeading: 'Edit department',
        directQualificationsSectHeading: 'Clinical qualifications',
        otherQualificationsSectHeading: 'Other qualifications',
        specialtiesSectHeading: 'Specialties',
        deptTypeSelection: {
          title: 'Type',
        },
        deptTitleSelection: {
          title: 'Title',
        },
        addSpecialtyButton: {
          label: 'Add specialty',
        },
        specialtySection: {
          title: 'Specialty',
        },
      },
      newSubDepartmentDialog: {
        dialogHeading: 'New sub-department',
        directQualificationsSectHeading: 'Clinical qualifications',
        otherQualificationsSectHeading: 'Other qualifications',
        specialtiesSectHeading: 'Specialties',
        deptParentTitleSelection: {
          title: 'Parent entity title',
        },
        deptTypeSelection: {
          title: 'Type',
        },
        deptTitleSelection: {
          title: 'Title',
        },
        addSpecialtyButton: {
          label: 'Add specialty',
        },
        specialtySection: {
          title: 'Specialty',
        },
      },
      editSubDepartmentDialog: {
        dialogHeading: 'Edit sub-department',
        directQualificationsSectHeading: 'Clinical qualifications',
        otherQualificationsSectHeading: 'Other qualifications',
        specialtiesSectHeading: 'Specialties',
        deptParentTitleSelection: {
          title: 'Parent entity title',
        },
        deptTypeSelection: {
          title: 'Type',
        },
        deptTitleSelection: {
          title: 'Title',
        },
        addSpecialtyButton: {
          label: 'Add specialty',
        },
        specialtySection: {
          title: 'Specialty',
        },
      },
    },
    locations: {
      listPage: {
        title: 'Locations',
        subtitle:
          'This section displays the list of locations included in your facility. In ‘Structure’ sub-tab you can view and edit your organization hierarchical tree and it’s entities',
        locationsCounter: '{amount} Locations',
        addLocationButtonLabel: 'Add location',
        table: {
          noRows: {
            title: 'No locations yet',
            subtitle: "You don't have any locations yet",
          },
          columns: {
            name: {
              heading: 'Location name',
            },
            region: {
              heading: 'Region',
            },
            contact: {
              heading: 'Main contact',
            },
            email: {
              heading: 'Email',
            },
            status: {
              heading: 'Status',
            },
          },
        },
      },
      newLocationPage: {
        heading: 'New location',
      },
      editPage: {
        backButton: {
          label: 'Back to Locations',
        },
        tabs: {
          general: {
            label: 'General details',
            form: {
              main: {
                heading: 'Main details',
                subtitle: "Enter the location's basic details such as name and contact information.",
                locationName: {
                  label: 'Location name',
                  placeholder: 'Please add a name for location',
                },
                isActive: {
                  label: 'Location is active',
                },
                isHeadquarters: {
                  label: 'Location is a headquarters',
                },
              },
              address: {
                heading: 'Location address',
                subtitle: 'Provide the full address for this location.',
              },
              contact: {
                heading: 'Main location contact',
                subtitle: 'Add the primary contact person’s details for this location.',
                primaryContactName: {
                  label: "Primary contact's full name",
                  placeholder: 'Enter full name',
                },
                contactJobTitle: {
                  label: 'Job title',
                  placeholder: 'Enter job title',
                },
                primaryPhoneNumber: {
                  label: 'Primary phone number',
                  placeholder: 'Enter job title',
                },
                secondaryPhoneNumber: {
                  label: 'Secondary phone number',
                  placeholder: 'Enter job title',
                },
              },
              operational: {
                heading: 'Operational setup',
                subtitle: 'Choose the starting day of the operational week for this location.',
              },
            },
          },
          structure: {
            label: 'Location structure',
            addDeptButton: {
              label: 'Add department',
            },
          },
        },
      },
    },
  },
  forms: {
    requiredFieldsMissing: '{number} mandatory fields missing',
    fieldIsRequired: 'This field is required',
    maxLengthError: 'Max length is {number}',
    invalidPhoneNumber: 'Please, enter valid phone number',
    invalidEIN: 'Please, check the format of the input',
    invalidZipCode: 'Please, enter valid zip code',
    invalidEmail: 'Please, enter valid email',
    emptyLinesOfBusiness: 'Select at least one business line of the organization',
    invalidWebsite: 'Please, enter valid website',
  },
  fields: {
    firstName: 'First name',
    firstNamePlaceholder: 'e.g. Jhon',
    lastName: 'Last name',
    lastNamePlaceholder: 'e.g. Doe',
    legalName: 'Legal name',
    agencyName: 'Agency name',
    agencyNamePlaceholder: 'Enter agency name',
    doingBusinessAs: 'Doing business as',
    agencyType: 'Agency type',
    agencyTypePlaceholder: 'Select agency type',
    registrationDate: 'Registration date',
    organizationWebsite: 'Organization website',
    organizationWebsitePlaceholder: 'Enter organization website',
    phone: 'Phone',
    phonePlaceholder: '+1 (XXX) XXX-XXXX',
    employerIdentificationNumber: 'Employer Identification Number (EIN)',

    primaryContactsFullName: 'Primary contact’s full name',
    primaryContactsFullNamePlaceholder: 'Enter primary contact’s full name',
    jobTitle: 'Job title',
    jobTitlePlaceholder: 'Enter job title',
    primaryPhoneNumber: 'Primary phone number',
    useOrganizationPhoneNumber: 'Use organization phone number',
    secondaryPhoneNumber: 'Secondary phone number',
    email: 'Email',
    emailPlaceholder: 'e.g. jhondoe@gmail.com',
    organizationsContactEmail: 'Enter organization’s contact email',

    addressLine1: 'Street address 1',
    addressLine1NoLocations: 'No locations',
    addressLine2: 'Street address 2',
    addressLinePlaceholder: 'Enter street name, number',
    cityAndState: 'City & State',
    region: 'Region',
    zipCode: 'Zip code',

    weekStartDay: 'Working week start',
    linesOfBusiness: 'Lines of business of the organization',
    linesOfBusinessShort: 'Lines of business',
    linesOfBusinessPlaceholder: 'Select lines of business',
    jobQualifications: 'Talent Job Qualifications',
    jobQualificationsPlaceholder: 'Select qualifications that apply',
    qualifications: 'What qualifications do you staff?',
    qualificationsPlaceholder: 'Select the qualifications you staff',

    professionals: 'Professionals',
    statuses: 'Statuses',
    timezone: 'Timezone',
    timezonePlaceholder: 'Select a timezone',
  },
  shifts: {
    type: {
      shifts: 'Shifts',
      series: 'Series & Rotations',
    },
    recurrence: {
      single: 'Single',
      series: 'Series',
      rotation: 'Rotation',
    },
    status: {
      assigned: 'Assigned',
      reAssigned: 'Re-assigned',
      pending: 'Pending',
      inProgress: 'In progress',
      completed: 'Completed',
      canceled: 'Canceled',
      na: 'N/A',
    },
    shiftType: {
      day8: '8h Day',
      eve8: '8h Eve',
      noc8: '8h Noc',
      day12: '12h Day',
      noc12: '12h Noc',
    },
    table: {
      noRows: {
        title: 'No shifts yet',
        subtitle: 'You don’t have any shifts yet',
      },
    },
    fields: {
      shiftType: 'Shift type',
      recurrence: 'Recurrence',
      location: 'Location',
      qualification: 'Qualification',
      qualifications: 'Qualifications',
      specialty: 'Specialty',
      status: 'Status',
      notes: 'Notes',
      assignee: 'Assignee',
      startDate: 'Start Date',
      shiftDate: 'Shift date',
      sharedWithin: 'Shared within',
      professionals: 'Professionals',
      cityAndState: 'City & State',
      locationName: 'Location name',
      address: 'Address',
      department: 'Department',
      subDepartment: 'Sub department',
    },
    lengthAndType: '{length}h, {type}',
    length: '{length}h',
    emptyNote: 'There is no note related to the shift',
    assignBtnLabel: 'Assign',
    assign: 'Assign',
    menuItemUnassign: 'Unassign',
    menuItemReAssign: 'Re-assign',
    assignSuccessful: 'Shift has been assigned successfully',
    unassignSuccessful: 'Shift has been unassigned successfully',
    unassignDialog: {
      title: 'Unassign shift',
      subtitle: 'Please, specify the reason',
      reasonLabel: 'Reason of unassigning',
      reasonPlaceholder: 'Select the reason',
      shiftDetails: '{period}, {length} {type}, {qualifications}, {assignee}',
      reasonCommentLabel: 'What is the issue?',
      reasonCommentPlaceholder: 'Please, specify the reason',
    },
    assignDialog: {
      title: 'Assign shift',
      placeholder: 'Please, specify the talent',
      shiftDetails: '{period}, {length} {type}, {qualifications}, {assignee}',
    },
    startDateAll: 'All',
    shiftDetails: 'Shift details',
    locationDetails: 'Location details',
    mainScheduler: 'Main scheduler',
    afterHoursScheduler: 'After-hours scheduler',
    generalInfo: 'General info',
    notes: 'Notes',
    billing: 'Billing',
    dayOneInstructions: 'Day one instructions',
    additionalNotes: 'Additional notes',
  },
  billing: {
    bonusRate: 'Bonus rate',
    rate: {
      perHour: 'Per hour',
      perShift: 'Per shift',
    },
    rates: 'Rates',
    totals: 'Totals',
    billRate: 'Bill rate',
    payRate: 'Pay rate',
    bonusAmount: 'Bonus amount',
    billAmount: 'Bill amount',
    payAmount: 'Pay amount',
    grossMargin: 'Gross margin',
    totalTime: 'Total time',
  },
  filters: {
    dateRange: {
      previousQuarter: 'Previous quarter',
      previousWeek: 'Previous week',
      today: 'Today',
      thisWeek: 'This week',
      thisQuarter: 'This quarter',
      nextWeek: 'Next week',
      nextQuarter: 'Next quarter',
    },
    sharedWithin: {
      menuLabel: 'Shared within',
      last24Hours: 'Last 24 hours',
      last48Hours: 'Last 48 hours',
      allTime: 'All time',
      last7Days: 'Last 7 days',
      last30Days: 'Last 30 days',
    },
    applyButtonLabel: 'Apply filters',
  },
  locations: {
    status: {
      Active: {
        tableChip: {
          label: ' Active',
        },
      },
      Deactivated: {
        tableChip: {
          label: 'Inactive',
        },
      },
    },
  },
});
